<template>
  <Editor
    apiKey="971axzht7cbmw45xc5m3q2i2vxjuxmklp3m3t2ycjh7x0od6"
    initialValue="<p>This is the initial content of the editor</p>" 
    :init="{
      height: 500,
      width: '100%',
      menubar: true,
      plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount',
      toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image',
      automatic_uploads: true,
      images_upload_url: 'https://tinymce.hereslife.com/api/FileUploader.php',
      images_upload_handler: example_image_upload_handler
    }"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'App',
  components: {
    Editor
  },
  methods: {
    handleImageUpload(blobInfo) {
      const url = 'https://tinymce.hereslife.com/api/FileUploader.php';
      //const url = 'http://localhost/tinymce-vue-demo/api/FileUploader.php'
      //const url = 'http://localhost/tinymce-vue-demo/public/api/FileUploader.php'
      return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          const formData = new FormData();
          xhr.withCredentials = true;
          xhr.open('POST', url);

          // Set Authorization header with your credentials
          xhr.setRequestHeader('Authorization', '12345');

          xhr.upload.onprogress = (e) => {
              console.log('Upload progress: ' + (e.loaded / e.total * 100) + '%');
          };

          xhr.onload = () => {
              if (xhr.status === 403) {
                  reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
                  return;
              }

              if (xhr.status < 200 || xhr.status >= 300) {
                  reject('HTTP Error: ' + xhr.status);
                  return;
              }

              try {
                  const jsonResponse = JSON.parse(xhr.responseText);
                  if (jsonResponse && jsonResponse.location) {
                      console.log(jsonResponse.location);
                      resolve(jsonResponse.location); // Pass the image URL to TinyMCE
                  } else {
                      reject('Invalid JSON: ' + xhr.responseText);
                  }
              } catch (e) {
                  reject('Failed to parse JSON: ' + xhr.responseText);
              }
          };

          xhr.onerror = () => {
              reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
          };

          formData.append('file', blobInfo.blob(), blobInfo.filename());
          xhr.send(formData);
      });
  
    }

  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
